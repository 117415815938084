<mat-card appearance="outlined">
    <div class="deployment-name-card">
        <button mat-icon-button (click)="goToSummary()" data-cy="back-button">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <h1 class="center-title" i18n>
            {{deployment?.name}} / {{deployment.kCellSerialNumber | uppercase}}
        </h1>
        <div></div>
    </div>
</mat-card>
<nav mat-tab-nav-bar #tabGroup mat-align-tabs="center" dynamicHeight [tabPanel]="tabPanel">
    <ng-container *ngFor="let tab of tabs">
        <a mat-tab-link
           *userHasRole="tab.userHasRole"
           [active]="activeTab === tab" data-name="{{tab.displayName}}"
           [routerLink]="tab.route" i18n [attr.data-cy]="'k-cell-tab-' + tab.displayName.toLowerCase()">
            {{tab.displayName}}
        </a>
    </ng-container>
</nav>
<mat-tab-nav-panel #tabPanel>
    <router-outlet></router-outlet>
</mat-tab-nav-panel>
