import {Component, Inject, OnInit} from "@angular/core";
import {AlertBannerService} from "@r3-iot/common";
import {DeviceDto, DeviceProfileDto, DeviceProfileService, DeviceService, UpdateDeviceDto} from "@r3-iot/api-sigma";
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import {finalize, take} from "rxjs";
import {Router} from "@angular/router";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { NgIf, NgFor } from "@angular/common";

@Component({
    selector: 'app-edit-device-dialog',
    templateUrl: './edit-device-dialog.component.html',
    styleUrls: ['./edit-device-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, MatDialogModule, MatButtonModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, MatProgressSpinnerModule]
})
export class EditDeviceDialogComponent implements OnInit {
    alertBannerOk = $localize`OK`;
    alertBannerDismiss = $localize`Dismiss`;
    saving = false;
    form: UntypedFormGroup;
    filteredDeviceProfiles: DeviceProfileDto[];
    currentDeviceProfile: DeviceProfileDto;
    loading = true;
    currentDevice: DeviceDto;

    constructor(private alertBannerService: AlertBannerService, private deviceProfileService: DeviceProfileService,
                private deviceService: DeviceService, public dialogRef: MatDialogRef<EditDeviceDialogComponent>,
                private router: Router, @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        this.currentDevice = this.data.device;
        this.currentDeviceProfile = this.data.deviceProfile;
        this.filteredDeviceProfiles = this.data.deviceProfileList.filter(
            x => x.supportsJoin === this.currentDeviceProfile.supportsJoin && x.name !== this.currentDeviceProfile.name);

        this.form = new UntypedFormGroup({
            deviceProfileSelect: new UntypedFormControl(null, [Validators.required])
        });
    }

    goToDeviceProfiles(): void {
        this.dialogRef.close();
        this.router.navigate(['home/device-profiles']);
    }

    save(): void {
        if (this.form.invalid) {return};
        this.dialogRef.disableClose = true;
        this.saving = true;

        const updateDeviceDto: UpdateDeviceDto = {
            deviceProfileName: this.form.controls.deviceProfileSelect.value.name
        };

        this.deviceService.v1DeviceDevEuiPut(this.currentDevice.devEui, updateDeviceDto)
            .pipe(take(1), finalize(() => {
                this.saving = false;
            }))
            .subscribe({
                next: res => {
                    this.dialogRef.close({successfulCreate: true, data: res});
                },
                error: error => {
                    this.dialogRef.close({successfulCreate: false, data: error.error});
                }
            });
    }
}
