import {mapToCanActivate, Routes} from "@angular/router";
import {AuthorizationGuard} from "../../../shared/guards/auth.guard";
import {DEVICES_ROUTES} from "./devices/routes";
import {K_CELL_ROUTES} from "./k-cell/routes";
import {DEPLOYMENT_DEVICE_ROUTES} from "./devices/devices-view/deployment-device/routes";
export const DEPLOYMENT_MANAGEMENT_ROUTES: Routes = [
    { path: '', redirectTo: 'summary', pathMatch: 'full'},
    { path: 'summary', loadComponent: () => import('./summary/summary.component').then(mod => mod.SummaryComponent),
        canActivate: mapToCanActivate([AuthorizationGuard])},
    { path: 'k-cell', loadComponent: () => import('./k-cell/k-cell.component').then(mod => mod.KCellComponent),
        loadChildren: () => K_CELL_ROUTES },
    { path: 'devices', loadComponent: () => import('./devices/devices.component').then(mod => mod.DevicesComponent),
        loadChildren: () => DEVICES_ROUTES },
    { path: 'plugins', loadComponent: () => import('./plugins/deployment-plugin-config.component').then(mod => mod.DeploymentPluginConfigComponent),
        canActivate: mapToCanActivate([AuthorizationGuard])},
    { path: 'plugins/:name/users', loadComponent: () => import('./plugins/deployment-plugin-users/deployment-plugin-users-config.component').then(mod => mod.DeploymentPluginUsersConfigComponent),
        canActivate: mapToCanActivate([AuthorizationGuard])},
    { path: 'devices/:devEui', loadComponent: () => import('./devices/devices-view/deployment-device/deployment-device.component').then(mod => mod.DeploymentDeviceComponent),
        loadChildren: () => DEPLOYMENT_DEVICE_ROUTES }
];
