import {mapToCanActivate, Routes} from "@angular/router";
import {AuthorizationGuard} from "../../../shared/guards/auth.guard";
export const PLUGIN_MANAGEMENT_ROUTES: Routes = [
    { path: '', redirectTo: 'edit', pathMatch: 'full'},
    {path: 'users', loadComponent: () => import('./plugin-users/plugin-users.component')
            .then(mod => mod.PluginUsersComponent), canActivate: mapToCanActivate([AuthorizationGuard])},
    {path: 'users/:username/deployments',
        loadComponent: () => import('./plugin-users/plugin-user-deployments/plugin-user-deployments.component')
            .then(mod => mod.PluginUserDeploymentsComponent), canActivate: mapToCanActivate([AuthorizationGuard])},
    { path: 'edit', loadComponent: () => import('./edit-plugin/edit-plugin.component').then(mod => mod.EditPluginComponent),
        canActivate: mapToCanActivate([AuthorizationGuard])},
    { path: 'message-configuration', loadComponent: () => import('./plugin-message-configuration/plugin-message-configuration.component')
            .then(mod => mod.PluginMessageConfigurationComponent), canActivate: mapToCanActivate([AuthorizationGuard])},
    { path: 'deployments', loadComponent: () => import('./deployment-plugin-config/plugin-deployment-config.component')
            .then(mod => mod.PluginDeploymentConfigComponent), canActivate: mapToCanActivate([AuthorizationGuard]) },
];
