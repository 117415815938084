import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import {$localize} from "@angular/localize/init";

@Component({
    selector: 'app-delete-acknowledge-dialog',
    templateUrl: './delete-acknowledge-dialog.component.html',
    styleUrls: ['./delete-acknowledge-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogModule, NgIf, MatButtonModule]
})

export class DeleteAcknowledgeDialogComponent {

  title: string;
  optionalMessage: string;
  buttonStringCancel = $localize`Cancel`;
  buttonStringDelete = $localize`Delete`;

  constructor(@Inject(MAT_DIALOG_DATA) data) {
    this.title = data.title;
    this.optionalMessage = data.optionalMessage;
  }

  protected readonly $localize = $localize;
}
