<h1 mat-dialog-title>{{title}}</h1>
<div mat-dialog-content>
  <div *ngIf="optionalMessage" data-cy="optional-message">{{optionalMessage}}</div><br *ngIf="optionalMessage"/>
  <div>Are you sure you want to perform this action?</div>

</div>
<div mat-dialog-actions>
  <button [attr.data-delete-dialog]="this.buttonStringCancel" mat-button [mat-dialog-close]="'close'">Cancel</button>
  <button [attr.data-delete-dialog]="this.buttonStringDelete" color="primary" mat-button [mat-dialog-close]="'delete'">Delete</button>
</div>
