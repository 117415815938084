import {Component, Inject, OnInit, ViewChild} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from "@angular/forms";
import {
    AddDeviceToDeploymentDto,
    CreateDeviceDto,
    DeploymentDto,
    DeploymentService,
    DeviceProfileDto,
    DeviceProfileService,
    DeviceService
} from "@r3-iot/api-sigma";
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import { AlertBannerService, R3CommonModule } from "@r3-iot/common";
import {Router} from "@angular/router";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {HEX_CHARS_X16_IGNORE_SPACE, HEX_CHARS_X32_IGNORE_SPACE} from "@r3-iot/common";
import { MatTable, MatTableDataSource, MatTableModule } from "@angular/material/table";
import {Tag} from "../../../../../../shared/models/tags.model";
import {
    NewTagDialogComponent
} from "../../../../../tags/new-tag-dialog/new-tag-dialog.component";
import {
    EditTagDialogComponent
} from "../../../../../tags/edit-tag-dialog/edit-tag-dialog.component";
import {
    DeleteAcknowledgeDialogComponent
} from "../../../../../../shared/dialogs/delete-acknowledge-dialog/delete-acknowledge-dialog.component";
import {DeploymentManagementService} from "../../../deployment-management.service";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatIconModule } from "@angular/material/icon";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { NgIf, NgFor } from "@angular/common";

@UntilDestroy()
@Component({
    selector: 'app-new-device-dialog',
    templateUrl: './new-device-dialog.component.html',
    styleUrls: ['./new-device-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, MatDialogModule, MatButtonModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, R3CommonModule, MatSelectModule, NgFor, MatOptionModule, MatIconModule, MatTableModule, MatProgressSpinnerModule]
})
export class NewDeviceDialogComponent implements OnInit{
    @ViewChild('tagsTable') tagsTable: MatTable<Tag>;
    deployment: DeploymentDto;
    form: UntypedFormGroup;
    deviceProfiles: DeviceProfileDto[];
    addDeviceToDeploymentArray: AddDeviceToDeploymentDto[] = [];
    saving = false;
    displayedTagsColumns = ['Key', 'Value', 'Operations'];
    tagsDatasource = new MatTableDataSource<Tag>();
    tagsAdded = false;
    typedDevEui: string;
    typedAppKey: string;
    typedAppSKey: string;
    typedNwkSKey: string;
    constructor( private deviceProfileService: DeviceProfileService, private deviceService: DeviceService,
                 public dialogRef: MatDialogRef<NewDeviceDialogComponent>, private deploymentsService: DeploymentService,
                 private router: Router, public dialog: MatDialog,
                 @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        this.deviceProfileService.v1DeviceprofileGet().subscribe({
            next: deviceProfiles => {
                this.deviceProfiles = deviceProfiles;
            }
        });

        this.deployment = this.data.deployment;

        this.form = new UntypedFormGroup({
            deviceName: new UntypedFormControl(null,[Validators.required]),
            devEui: new UntypedFormControl(null,[Validators.required, Validators.pattern(HEX_CHARS_X16_IGNORE_SPACE)]),
            deviceProfileSelect: new UntypedFormControl(null,[Validators.required]),
            //OTAA Profile selected
            applicationKey: new UntypedFormControl(null,[Validators.pattern(HEX_CHARS_X32_IGNORE_SPACE)]),
            //ABP Profile selected
            networkSessionKey: new UntypedFormControl(null,[Validators.pattern(HEX_CHARS_X32_IGNORE_SPACE)]),
            applicationSessionKey: new UntypedFormControl(null,[Validators.pattern(HEX_CHARS_X32_IGNORE_SPACE)]),
            //Tags
            tags: new UntypedFormControl(this.data ? this.data.tags : null),
            lat: new UntypedFormControl(null),
            lng: new UntypedFormControl(null)
        });

        this.form.controls.deviceProfileSelect.valueChanges.pipe(untilDestroyed(this)).subscribe({
            next: change => {
                this.activationModeChanged(change);
            }
        });
    }

    activationModeChanged(deviceProfile: DeviceProfileDto): void {
        if (deviceProfile.supportsJoin === true){
            // OTAA device profile
            this.form.controls.networkSessionKey.removeValidators([Validators.required]);
            this.form.controls.networkSessionKey.updateValueAndValidity();
            this.form.controls.applicationSessionKey.removeValidators([Validators.required]);
            this.form.controls.applicationSessionKey.updateValueAndValidity();
            this.form.controls.applicationKey.addValidators([Validators.required]);
            this.form.controls.applicationKey.updateValueAndValidity();

        } else if (deviceProfile.supportsJoin === false) {
            // ABP device profile
            this.form.controls.applicationKey.removeValidators([Validators.required]);
            this.form.controls.applicationKey.updateValueAndValidity();

            this.form.controls.networkSessionKey.addValidators([Validators.required]);
            this.form.controls.networkSessionKey.updateValueAndValidity();
            this.form.controls.applicationSessionKey.addValidators([Validators.required]);
            this.form.controls.applicationSessionKey.updateValueAndValidity();
        }
        this.form.controls.applicationKey.updateValueAndValidity();
        this.form.controls.networkSessionKey.updateValueAndValidity();
        this.form.controls.applicationSessionKey.updateValueAndValidity();
    }

    goToDeviceProfiles(): void {
        this.router.navigate(['home/device-profiles']);
        this.dialogRef.close();

    }

    save(): void {
        if (this.form.invalid) {return};
        this.dialogRef.disableClose = true;
        this.saving = true;

        this.deviceService.v1DeviceDevEuiGet(this.form.controls.devEui.value.replace(/\s/g, '')).subscribe({
            next: preExistingDevice => {
                this.addDeviceToDeployment();
                this.saving = false;
                let res = {successfulCreate: true, data: preExistingDevice.devEui}
                this.dialogRef.close(res);
            },
            error: error => {
                if (error.status === 404) {
                    this.createDeviceAndAddToDeployment();
                } else {
                    let res = {successfulCreate: false, data: error}
                    this.saving = false;
                    this.dialogRef.disableClose = false;
                    this.dialogRef.close(res);
                }
            }
        });
    }

    addDeviceToDeployment(): void {
        const addDeviceToDeployment: AddDeviceToDeploymentDto = {
            devEui: this.form.controls.devEui.value.replace(/\s/g, ''),
            deviceProfileName: this.form.controls.deviceProfileSelect.value.name,
            deviceLatitude: this.form.controls.lat.value ?? null,
            deviceLongitude: this.form.controls.lng.value ?? null
        };

        this.addDeviceToDeploymentArray.push(addDeviceToDeployment);

        this.deploymentsService.v1DeploymentNameDevicePost(this.deployment.name, this.addDeviceToDeploymentArray).subscribe({
            next: () => {
                //do nothing, alert banner displays in save method if this has not been called after creating a device
            },
            error: error => {
                this.saving = false;
                this.dialogRef.disableClose = false;
                let res = {successfulCreate: false, data: error.error};
                this.dialogRef.close(res);
            }
        });
    }

    createDeviceAndAddToDeployment(): void {
        //if device does not exist, create it and add it to the deployment-management
        const createDevice: CreateDeviceDto = {
            name: this.form.controls.deviceName.value,
            devEui: this.form.controls.devEui.value.replace(/\s/g, ''),
            appKey: this.form.controls.deviceProfileSelect.value.supportsJoin === true ? this.form.controls.applicationKey.value.replace(/\s/g, '') : null,
            appSkey: this.form.controls.deviceProfileSelect.value.supportsJoin === false ? this.form.controls.applicationSessionKey.value.replace(/\s/g, '') : null,
            nwkSkey: this.form.controls.deviceProfileSelect.value.supportsJoin === false ? this.form.controls.networkSessionKey.value.replace(/\s/g, '') : null,
            tags: this.getTags(),
            deviceProfileName: this.form.controls.deviceProfileSelect.value.name,
            deviceLatitude: this.form.controls.lat.value ?? null,
            deviceLongitude: this.form.controls.lng.value ?? null
        };

        this.deviceService.v1DevicePost(createDevice).subscribe({
            next: newDevice => {
                if (newDevice){
                    this.addDeviceToDeployment();
                    this.saving = false;
                    let res = {successfulCreate: true, data: newDevice};

                    this.dialogRef.close(res);
                }
            },
            error: error => {
                this.saving = false;
                this.dialogRef.disableClose = false;
                let res = {successfulCreate: false, data: error.error};

                this.dialogRef.close(res);
            }
        });
    }

    getTags() {
        let tagsObject = {}
        if ( this.tagsDatasource.data.length > 0 ) {
            this.tagsDatasource.data.forEach(function (key) {
                tagsObject[key.key] = key.value;
            });
        }
        return tagsObject;
    }

    openAddTagsDialog(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'krucial-medium-modal-container';
        this.dialog.open(NewTagDialogComponent, dialogConfig).afterClosed()
            .subscribe( {
                next: (response: any) => {
                    if ( response ) {
                        this.tagsAdded = true;
                        this.tagsDatasource.data.push(response); // first time executed this adds a reference to data so triggers change detection
                        if (this.tagsTable) {this.tagsTable.renderRows()}; // manually refresh, initially we don't have a tagsTable until we add data
                    }
                }});
    }

    openEditTagDialog(event, editedTag: any): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'krucial-medium-modal-container';
        dialogConfig.data = {
            Id: editedTag.id,
            key: editedTag.key,
            value: editedTag.value,
        };
        this.dialog.open(EditTagDialogComponent, dialogConfig).afterClosed()
            .subscribe({
                next: (response: any) => {
                    this.tagsDatasource.data.forEach(function(object) {
                        if (object.key === editedTag.key) { object.value = response.value; }
                    });
                    this.tagsTable.renderRows();
                }
            });
    }

    openDeleteTagDialog(event, deletedTag: any): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "25.75rem";
        dialogConfig.data = {
            recordType: 'Tag'
        };
        this.dialog.open(DeleteAcknowledgeDialogComponent, dialogConfig).afterClosed()
            .subscribe( {
                next: (response: any) => {
                    if (response === 'delete') {
                        const elementPos = this.tagsDatasource.data.findIndex(x => x["key"] === deletedTag.key)
                        if (elementPos > -1){
                            this.tagsDatasource.data.splice(elementPos, 1);
                            this.tagsTable.renderRows();
                        }
                        if (this.tagsDatasource.data.length == 0) {  // hide table if we have no tags
                            this.tagsAdded = false;
                        }
                    }
                }
            });
    }
}
